/* eslint-disable no-undef */
<template>
  <div class="base-cost-pop common-pop">
    <el-dialog
      :title="todo == 'view' ? '【查看】提成费用信息' : '【编辑】提成费用信息'"
      :close-on-click-modal="false"
      width="80%"
      :visible.sync="visible">
      <div class="modularLabel modularLabel1">
        <label><span class="separate">|</span>订单信息</label>
      </div>
      <div class="modularBox modularBox1">
        <el-form :model="dataForm" ref="dataForm" label-width="96px" size="small">
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="订单编号">
                <el-input v-model="dataForm.orderNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单类型">
                <el-select class="item-choose" v-model="dataForm.orderType" :disabled="true" style="width: 100%;">
                  <el-option v-for="item in dictTypeMap.orderType" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单车型">
                <el-select class="item-choose" v-model="dataForm.vehicleType" :disabled="true" style="width: 100%;">
                  <el-option v-for="item in dictTypeMap.vehicle_type" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单状态">
                <el-input v-model="dataForm.status" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="提货时间">
                <el-input v-model="dataForm.takeGoodsDate" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户名称">
                <el-input v-model="dataForm.customerName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" v-if="chargeType">
              <el-form-item label="客户业务员">
                <el-input v-model="dataForm.customerBusinessName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="进仓单号">
                <el-input v-model="dataForm.inWarehouseNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50" v-for="(item) in dataForm.addressList" :key="item.id">
            <el-col :span="24">
              <el-form-item :label="item.type === 1 ? '提货方' : item.type === 2 ? '卸货方' : '报关方'">
                <el-col :span="8">
                  <el-input v-model="item.factoryName" :disabled="true"></el-input>
                </el-col>
                <el-col :span="16">
                  <el-input v-model="item.fullAddress" :disabled="true"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="司机车牌">
                <el-input v-model="dataForm.vehiclePlan.vehiclePlate" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机姓名">
                <el-input v-model="dataForm.vehiclePlan.driverName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机电话">
                <el-input v-model="dataForm.vehiclePlan.driverPhone" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="车次号" prop="planNo">
                <el-input v-model="dataForm.vehiclePlan.planNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="件数">
                <el-input v-model="dataForm.orderGoods.pieceNum" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="重量">
                <el-input v-model="dataForm.orderGoods.weight" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="体积">
                <el-input v-model="dataForm.orderGoods.volume" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户单号">
                <el-input v-model="dataForm.customerSelfNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="客户联系人">
                <el-input v-model="dataForm.customerContactName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户联系电话">
                <el-input v-model="dataForm.customerContactPhone" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="下单时间">
                <el-input v-model="dataForm.commitTime" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="下单人">
                <el-input v-model="dataForm.commitName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="订单备注">
                <el-input v-model="dataForm.remark" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="异常备注">
                <el-input v-model="dataForm.groupExplain" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="24">
              <el-steps :active="statusActive" align-center finish-status="success">
                <el-step title="派车时间" :description="dispatchTime"></el-step>
                <el-step title="出车时间" :description="outDriveTime"></el-step>
                <el-step title="装货到场时间" :description="arrivedTime"></el-step>
                <el-step title="装货离场时间" :description="loadGoodsTime"></el-step>
                <el-step title="卸货到场时间" :description="arrivedGiveTime"></el-step>
                <el-step title="签收时间" :description="successTime"></el-step>
              </el-steps>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="modularLabel modularLabel2">
        <label><span class="separate">|</span>客户费用（应收）</label>
      </div>
      <div class="modularBox modularBox2">
        <el-form :model="costInfo" ref="dataForm" label-width="100px" :rules="rules" size="small">
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="金额小计" prop="totalMoney">
                <el-input v-model="costInfo.totalMoney" readonly="readonly"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="运费" prop="freightFee">
                <el-input v-model="costInfo.freightFee" :disabled="true" placeholder="运费"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="报关/入仓费" prop="entranceFee">
                <el-input v-model="costInfo.entranceFee"  :disabled="true" placeholder="报关/入仓费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.entranceFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="高速费" prop="highFee">
                <el-input v-model="costInfo.highFee"  :disabled="true" placeholder="高速费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.highFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="过磅费" prop="weighFee">
                <el-input v-model="costInfo.weighFee"  :disabled="true" placeholder="过磅费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.weighFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="装卸费" prop="loadFee">
                <el-input v-model="costInfo.loadFee"  :disabled="true" placeholder="装卸费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.loadFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="押夜超时费" prop="overtimeFee">
                <el-input v-model="costInfo.overtimeFee" :disabled="true" placeholder="押夜超时费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.overtimeFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="提货费" prop="takeFee">
                <el-input v-model="costInfo.takeFee"  :disabled="true" placeholder="提货费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.takeFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="停车费" prop="parkingFee">
                <el-input v-model="costInfo.parkingFee"  :disabled="true" placeholder="停车费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.parkingFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="加点费" prop="addFee">
                <el-input v-model="costInfo.addFee" :disabled="true" placeholder="加点费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.addFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="其他费用" prop="otherFee">
                <el-input v-model="costInfo.otherFee"  :disabled="true" placeholder="其他费用">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.otherFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="其他费用备注" prop="otherFeeRemark">
                <el-input v-model="costInfo.otherFeeRemark" :disabled="true" placeholder="其他费用备注"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="modularLabel modularLabel2">
        <label><span class="separate">|</span>成本费用（应付）</label>
      </div>
      <div class="modularBox modularBox2">
        <el-form  :model="payCostInfo" ref="dataForm" label-width="100px" :rules="payRules" size="small">
          <el-row  :gutter="50">
            <el-col :span="6">
              <el-form-item label="金额小计" prop="auditTotalMoney">
                <el-input v-model="payCostInfo.auditTotalMoney" readonly="readonly"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="运费" prop="freightFee">
                <el-input v-model="payCostInfo.freightFee" :disabled="true" placeholder="运费"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="押夜超时费" prop="overtimeFee">
                <el-input v-model="payCostInfo.overtimeFee" :disabled="true" placeholder="押夜超时费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.overtimeFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="加点费" prop="addFee">
                <el-input v-model="payCostInfo.addFee" :disabled="true" placeholder="加点费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.addFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="过磅费" prop="weighFee">
                <el-input v-model="payCostInfo.weighFee" :disabled="true" placeholder="过磅费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.weighFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="装卸费" prop="loadFee">
                <el-input v-model="payCostInfo.loadFee" :disabled="true" placeholder="装卸费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.loadFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="报关/入仓费" prop="entranceFee">
                <el-input v-model="payCostInfo.entranceFee" :disabled="true" placeholder="报关/入仓费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.entranceFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="停车费" prop="parkingFee">
                <el-input v-model="payCostInfo.parkingFee" :disabled="true" placeholder="停车费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.parkingFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
<!--            <el-col :span="6">-->
<!--              <el-form-item label="提货费" prop="takeFee">-->
<!--                <el-input v-model="payCostInfo.takeFee" :disabled="true" placeholder="提货费">-->
<!--                  <el-button slot="append" type="text" @click="preview(imgTypeList.takeFee, payCostInfo.id)">预览</el-button>-->
<!--                </el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="高速费" prop="highFee">
                <el-input v-model="payCostInfo.highFee" :disabled="true" placeholder="高速费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.highFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="打印费" prop="printFee">
                <el-input v-model="payCostInfo.printFee" :disabled="true" placeholder="打印费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.printFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="维修费" prop="maintainFee">
                <el-input v-model="payCostInfo.maintainFee" :disabled="true" placeholder="加点费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.maintainFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="现金加油费" prop="refuelFee">
                <el-input v-model="payCostInfo.refuelFee" :disabled="true" placeholder="现金加油费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.refuelFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="现金过路费" prop="passingFee">
                <el-input v-model="payCostInfo.passingFee" :disabled="true" placeholder="现金过路费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.passingFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="油卡费" prop="refuelCardFee">
                <el-input v-model="payCostInfo.refuelCardFee" :disabled="true" placeholder="油卡费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.refuelCardFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="粤通卡费" prop="passingCardFee">
                <el-input v-model="payCostInfo.passingCardFee" :disabled="true" placeholder="粤通卡费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.passingCardFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="其他费用" prop="otherFee">
                <el-input v-model="payCostInfo.otherFee" :disabled="true" placeholder="其他费用">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.otherFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="24">
              <el-form-item label="其他费用备注" prop="otherFeeRemark">
                <el-input v-model="payCostInfo.otherFeeRemark" :disabled="true" placeholder="其他费用备注"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="司机借支" prop="driverBorrowing">
                <el-input v-model="payCostInfo.driverBorrowing" :disabled="true" placeholder="司机借支">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="扣款" prop="deductionsFee">
                <el-input v-model="payCostInfo.deductionsFee" :disabled="true" placeholder="社保/出险扣款">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="借支/扣款备注" prop="borrowingRemark">
                <el-input v-model="payCostInfo.borrowingRemark" :disabled="true" placeholder="社保扣款/出险扣款备注">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="modularLabel modularLabel2">
        <label><span class="separate">|</span>司机提成费用</label>
      </div>
      <div class="modularBox modularBox2">
        <el-form :model="driverRoyalty" ref="dataForm" label-width="100px" :rules="driverRules" size="small">
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="应收运费" prop="freightFee">
                <el-input v-model="driverRoyalty.freightFee" @input="countBaseMoney()" placeholder="应收运费"
                          :disabled="isViews || !royaltyFeeBox"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="加点费" prop="addFee">
                <el-input v-model="driverRoyalty.addFee" @input="countBaseMoney()" placeholder="加点费"
                          :disabled="isViews || !royaltyFeeBox">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="压夜超时费" prop="overtimeFee">
                <el-input v-model="driverRoyalty.overtimeFee" @input="countBaseMoney()" placeholder="压夜超时费"
                          :disabled="isViews || !royaltyFeeBox">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机提成费" prop="royaltyFee">
                <el-input v-model="driverRoyalty.royaltyFee" :disabled="true">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="装卸费" prop="loadFee">
                <el-input v-model="driverRoyalty.loadFee" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.loadFeeBox" placeholder="装卸费">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="加班费" prop="extraFee">
                <el-input v-model="driverRoyalty.extraFee" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.extraFeeBox" placeholder="加班费">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="住宿费" prop="stayFee">
                <el-input v-model="driverRoyalty.stayFee" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.stayFeeBox" placeholder="住宿费">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="安全奖罚金" prop="safetyRewards">
                <el-input v-model="driverRoyalty.safetyRewards" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.safetyRewardsBox" placeholder="安全奖罚金">
                </el-input>
              </el-form-item>
            </el-col>
<!--            <el-col :span="6">-->
<!--              <el-form-item label="基本工资/全勤" prop="payFreightFee">-->
<!--                <el-input v-model="driverRoyalty.payFreightFee" :disabled="isViews">-->
<!--                </el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
          <el-row :gutter="50">
<!--            <el-col :span="6">-->
<!--              <el-form-item label="工资基数" prop="wageBase">-->
<!--                <el-input v-model="wageBase" :disabled="isViews"  @input="calculateReward" placeholder="输入计算基本工资">-->
<!--                </el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
            <el-col :span="6">
              <el-form-item label="基本工资" prop="baseFee">
                <el-input v-model="driverRoyalty.baseFee" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.baseFeeBox" placeholder="基本工资">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="绩效奖罚金" prop="meritsFee">
                <el-input v-model="driverRoyalty.meritsFee" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.meritsFeeBox" placeholder="绩效奖罚金">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="APP奖罚金" prop="appFee">
                <el-input v-model="driverRoyalty.appFee" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.appFeeBox" placeholder="APP奖罚金">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="租房费" prop="other1Fee">
                <el-input v-model="driverRoyalty.other1Fee" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.other1FeeBox" placeholder="租房费">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="油耗奖励" prop="other2Fee">
                <el-input v-model="driverRoyalty.other2Fee" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.other2FeeBox" placeholder="油耗奖励">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机费用合计"  prop="payableAmount" class="item">
                <el-input v-model="driverRoyalty.payableAmount" :disabled="true" placeholder="司机提成+装卸+加班+住宿+基本+绩效+APP+租房+油耗+安全">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="请假扣款" prop="accumulationFund">
                <el-input v-model="driverRoyalty.accumulationFund" :disabled="isViews || driverRoyalty.payRequestConfig.accumulationFundBox" placeholder="请假扣款">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="扣减安全奖" prop="safetyAward">
                <el-input v-model="driverRoyalty.safetyAward" :disabled="isViews || driverRoyalty.payRequestConfig.safetyAwardBox" placeholder="扣减安全奖">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="扣减违章/油耗" prop="oilConsumption">
                <el-input v-model="driverRoyalty.oilConsumption" :disabled="isViews || driverRoyalty.payRequestConfig.oilConsumptionBox" placeholder="扣减违章/油耗">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="分摊水电费" prop="hydropower">
                <el-input v-model="driverRoyalty.hydropower" :disabled="isViews || driverRoyalty.payRequestConfig.hydropowerBox" placeholder="分摊水电费">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="个税" prop="personalIncomeTax">
                <el-input v-model="driverRoyalty.personalIncomeTax" :disabled="isViews || driverRoyalty.payRequestConfig.personalIncomeTaxBox" placeholder="个税">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="扣社保/公积金" prop="deductionsFee">
                <el-input v-model="driverRoyalty.deductionsFee" :disabled="isViews || driverRoyalty.payRequestConfig.deductionsFeeBox" placeholder="社保/公积金扣款">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="6">
              <el-form-item label="其他费用(扣)" prop="otherExpenses">
                <el-input v-model="driverRoyalty.otherExpenses" :disabled="isViews || driverRoyalty.payRequestConfig.otherExpensesBox" placeholder="其他费用(扣)">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机借支" prop="driverBorrowing">
                <el-input v-model="driverRoyalty.driverBorrowing" :disabled="isViews || driverRoyalty.payRequestConfig.driverBorrowingBox" placeholder="司机借支">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="借支/扣款备注" prop="remark">
                <el-input v-model="driverRoyalty.remark" type="textarea" :disabled="isViews" placeholder="借支/扣款说明">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="todo !== 'view'">确定</el-button>
      </span>
    </el-dialog>
    <!-- <big-image ref="bigImg"></big-image> -->
    <!-- 查看图片 -->
    <view-image ref="viewPhoto" :limit="1"></view-image>
  </div>
</template>

<script>
// import bigImage from '@/views/commonPop/bigImage'
import viewImage from '@/views/commonPop/viewImage-fy'
export default {
  components: {
    // bigImage
    viewImage
  },
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        orderInfo: {},
        pieceNum: null,
        weight: null,
        volume: null,
        vehiclePlan: {},
        orderGoods: {}
      },
      statusActive: 1,
      dispatchTime: null,
      outDriveTime: null,
      arrivedTime: null,
      loadGoodsTime: null,
      arrivedGiveTime: null,
      successTime: null,
      todo: 'view',
      costInfo: {},
      payCostInfo: {},
      driverRoyalty: {},
      globalCostConfig: {},
      imgTypeList: {
        // 报关/入仓
        entranceFee: [7050],
        // 高速费
        highFee: [7051],
        // 过磅费
        weighFee: [7052],
        // 装卸费
        loadFee: [7053],
        // 押夜超时费
        overtimeFee: [7054],
        // 提货费
        takeFee: [7055],
        // 加点费
        addFee: [7056],
        // 其他费用
        otherFee: [7057],
        // 加油费
        refuelFee: [7001],
        // 过路费
        passingFee: [7002],
        // 打印费
        printFee: [7003],
        // 维修费
        maintainFee: [7004],
        parkingFee: [7008],
        refuelCardFee: [7006],
        passingCardFee: [7007]
      },
      chargeType: true,
      rules: {
        freightFee: this._Valid.canNullinit(['nullOrPrice']),
        loadFee: this._Valid.canNullinit(['nullOrPrice']),
        overtimeFee: this._Valid.canNullinit(['nullOrPrice']),
        addFee: this._Valid.canNullinit(['nullOrPrice']),
        extraFee: this._Valid.canNullinit(['nullOrPrice']),
        stayFee: this._Valid.canNullinit(['nullOrPrice'])
      },
      royaltyFeeBox: false,
      wageBase: null,
      payRules: {},
      driverRules: {}
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    isViews () {
      return this.todo === 'view' || this.driverRoyalty.status === 1
    }
  },
  methods: {
    clearOldValue () {
      this.statusActive = 1
      this.dispatchTime = null
      this.outDriveTime = null
      this.arrivedTime = null
      this.loadGoodsTime = null
      this.arrivedGiveTime = null
      this.successTime = null
    },
    init (type, todo, row) {
      this.visible = true
      this.royaltyFeeBox = false
      this.wageBase = null
      this.todo = todo || 'view'
      this.dataForm = row
      // this.getGlobalCostConfig()
      this.clearOldValue()
      this.$http({
        url: this.$http.adornUrl(`/costInfo/auditDetail/${row.orderInfo.id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.dataForm = data
        this.globalCostConfig = data.driverInfo
        this.dataForm.status = this.dictTypeMap.orderStatusMap[data.status].name
        this.costInfo = data.costInfo
        this.payCostInfo = data.payCostInfo
        this.driverRoyalty = data.driverRoyalty
        this.dataForm.takeGoodsDate = data.takeGoodsDate + ' ' + data.takeGoodsTime
        let payRequestConfig = this.driverRoyalty.payRequestConfig
        this.royaltyFeeBox = !payRequestConfig.royaltyFeeBox && !payRequestConfig.addFeeBox && !payRequestConfig.overtimeFeeBox
        let recordList = data.recordList
        this.dispatchTime = data.dispatchTime
        if (recordList) {
          for (let i = 0; i < recordList.length; i++) {
            let record = recordList[i]
            if (record.status === 5) {
              this.outDriveTime = record.operationTime
              this.statusActive = 2
            } else if (record.status === 6) {
              this.arrivedTime = record.operationTime
              this.statusActive = 3
            } else if (record.status === 7) {
              this.loadGoodsTime = record.operationTime
              this.statusActive = 4
            } else if (record.status === 15) {
              this.arrivedGiveTime = record.operationTime
              this.statusActive = 5
            } else if (record.status === 8) {
              this.successTime = record.operationTime
              this.statusActive = 6
            }
          }
        }
      })
    },
    // 图片预览
    preview (typeList, id) {
      this.$refs.viewPhoto.init(id, typeList)
    },
    getDefaultValue (value, defaultValue) {
      if (value === undefined || value === null) {
        return defaultValue
      }
      let number = Number(value)
      if (number === undefined || number === null) {
        return defaultValue
      }
      return number
    },
    countBaseMoney () {
      if (this.royaltyFeeBox) {
        let sumCost = this.getDefaultValue(this.driverRoyalty.freightFee, 0) +
          this.getDefaultValue(this.driverRoyalty.addFee, 0) +
          this.getDefaultValue(this.driverRoyalty.overtimeFee, 0)
        // 司机提成
        if (sumCost > 0 && this.dataForm.driverRoyaltyRatio && this.dataForm.driverRoyaltyRatio > 0) {
          this.driverRoyalty.royaltyFee = this.calculateRatioValue(sumCost, this.dataForm.driverRoyaltyRatio)
        } else {
          this.driverRoyalty.royaltyFee = 0
        }
        // this.calculateReward()
        this.calculateRoyaltyTotal()
      }
    },
    // 计算比例值
    calculateRatioValue (value, ratio) {
      if (!value) {
        return ''
      }
      let number = Number(value) * (ratio / 100)
      if (number <= 0 || number == null) {
        return ''
      }
      // 保留2位小数
      return number.toFixed(2)
    },
    // 计算奖励费用
    calculateReward () {
      let freightFee = this.wageBase
      let payRequestConfig = this.driverRoyalty.payRequestConfig
      if (!payRequestConfig.baseFeeBox) {
        this.driverRoyalty.baseFee = this.calculateRatioValue(freightFee, this.globalCostConfig.baseWageRatio)
      }
      if (!payRequestConfig.meritsFeeBox) {
        this.driverRoyalty.meritsFee = this.calculateRatioValue(freightFee, this.globalCostConfig.meritsWageRatio)
      }
      if (!payRequestConfig.appFeeBox) {
        this.driverRoyalty.appFee = this.calculateRatioValue(freightFee, this.globalCostConfig.useAppReward)
      }
      if (!payRequestConfig.other1FeeBox) {
        this.driverRoyalty.other1Fee = this.calculateRatioValue(freightFee, this.globalCostConfig.other1Reward)
      }
      if (!payRequestConfig.other2FeeBox) {
        this.driverRoyalty.other2Fee = this.calculateRatioValue(freightFee, this.globalCostConfig.other2Reward)
      }
      if (!payRequestConfig.safetyRewardsBox) {
        this.driverRoyalty.safetyRewards = this.calculateRatioValue(freightFee, this.globalCostConfig.safetyRewards)
      }
      this.calculateRoyaltyTotal()
    },
    // 合计费用
    calculateRoyaltyTotal () {
      let royalty = this.driverRoyalty
      let royaltyTotal = this.getDefaultValue(royalty.royaltyFee, 0) +
        this.getDefaultValue(royalty.loadFee, 0) +
        this.getDefaultValue(royalty.extraFee, 0) +
        this.getDefaultValue(royalty.stayFee, 0) +
        this.getDefaultValue(royalty.baseFee, 0) +
        this.getDefaultValue(royalty.meritsFee, 0) +
        this.getDefaultValue(royalty.appFee, 0) +
        this.getDefaultValue(royalty.other1Fee, 0) +
        this.getDefaultValue(royalty.other2Fee, 0) +
        this.getDefaultValue(royalty.safetyRewards, 0)
      royalty.payableAmount = this.getDefaultValue(royaltyTotal, 0).toFixed(2)
    },
    getGlobalCostConfig () {
      this.globalCostConfig = {}
      this.$http({
        url: this.$http.adornUrl('/dict/findInfoList'),
        method: 'get',
        params: this.$http.adornParams({
          typeCode: 'royalty_config'
        })
      }).then(({ data }) => {
        for (let i = 0; i < data.length; i++) {
          this.globalCostConfig[data[i].code] = Number(data[i].name)
        }
      })
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/costInfo/update/baseCost`),
            method: 'POST',
            data: this.$http.adornData(this.driverRoyalty)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    }
  }
}
</script>
  <style lang="less">
  .base-cost-pop {
    .el-form-item {
      margin-bottom: 0px;
    }
    .el-dialog {
      // margin-top: 20vh !important;
      .el-input__inner {
        height: 26px !important;
      }
      .el-dialog__header {
        padding: 10px 20px 10px;
      }
    }
    .el-dialog__body {
      padding: 0;
      max-height: 960px;
      overflow: auto;
    }
    .el-dialog__footer {
      padding: 10px 20px 10px;
      .el-button {
        height: 32px;
        line-height: 0;
      }
    }
    .modularBox {
      // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
      margin-left: 20px;
      margin-right: 20px;
      .el-col {
        padding-right: 0 !important;
      }
    }
    .modularBox1 {
      padding-right: 40px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .modularBox2 {
      padding-top: 5px;
      padding-bottom: 5px;
      padding-right: 40px;
      margin-bottom: 2px;
      .addressBtn {
        width: 100%;
        padding: 9px 0px;
        text-align: center
      }
      .deleteBtn {
        width: 100%;
        height: 40px;
        padding-top: 5px;
        text-align: right;
      }
    }
    .modularBox3 {
      padding: 25px;
      .carBox {
        width: 320px;
        margin-right: 20px;
        // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
        text-align: center;
        cursor: pointer;
      }
    }
    .modularBox4 {
      padding: 25px;
    }
    .modularLabel {
      margin-bottom: 5px;
      padding-left: 20px;
      margin-top: 0px;
      span.separate {
        display: inline-block;
        width: 5px;
        margin-right: 10px;
        background: #17B3A3;
        color: transparent;
        height: 20px;
        line-height: 20px;
      }
      label {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .modularLabel2 {
      margin-top: 10px;
      line-height: 30px;
      height: 30px;
      label {
        min-width: 120px;
        float: left;
      }
    }
    .footer {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 30px;
      text-align: right;
      padding: 25px;
      .explain {
        color: #17B3A3 !important
      }
    }
  }
  </style>
